<template>
  <v-form ref="form" v-model="isValid" @submit.prevent="handleSubmit">
    <base-text-field
      autocapitalize
      autofocus
      required
      v-model="formData.street"
      :label="$t('street')"
      :placeholder="$t('street')"
      :rules="rulesGenerics"
    />
    <base-text-field
      required
      v-model="formData.postal_code"
      :label="$t('postalCode')"
      :placeholder="$t('postalCode')"
      :rules="rulesGenerics"
    />
    <base-text-field
      autocapitalize
      required
      v-model="formData.city"
      :label="$t('city')"
      :placeholder="$t('city')"
      :rules="rulesGenerics"
    />
    <base-text-field
      autocapitalize
      required
      v-model="formData.state"
      :label="$t('state')"
      :placeholder="$t('state')"
      :rules="rulesGenerics"
    />
    <base-textarea
      textarea
      v-model="formData.reference"
      :label="$t('comment')"
      :placeholder="$t('comment')"
    />
    <footer class="d-flex justify-center">
      <base-btn
        :color="!theme.isDark ? 'secondary' : 'white'"
        type="submit"
        class="my-2 my-md-0"
        :disabled="!isValid"
        block
      >
        {{ $t("create") }}
      </base-btn>
    </footer>
  </v-form>
</template>

<script>
import { addressesCtrl } from "@/controllers";
import {
  getErrorPayloadForSnackbar,
  rulesGenerics,
  rulesLetters,
} from "@/helpers";
import { useSnackbar } from "@/utils";

const iniTialFormData = {
  street: "",
  postal_code: "",
  city: "",
  state: "",
  reference: "",
};

export default {
  name: "AddAddressForm",
  inject: ["theme", "createAnAddress", "handleCloseDialog"],
  data() {
    return {
      formData: { ...iniTialFormData },
      isValid: false,
      rulesGenerics,
      rulesLetters,
    };
  },
  methods: {
    async handleSubmit() {
      try {
        const form = this.$refs.form;
        const isValid = await form.validate();
        if (!isValid) return;
        const { data, message } = await addressesCtrl.handleCreateAddress({
          ...this.formData,
        });
        if (data && message) {
          const snackbar = useSnackbar();
          snackbar.dispatchOpenSnackbar(message);
          this.formData = { ...iniTialFormData };
          form.inputs[0].focus();
          form.resetValidation();
          this.createAnAddress({ ...data });
          this.handleCloseDialog();
        }
      } catch (error) {
        const errorPayload = getErrorPayloadForSnackbar(error);
        this.$store.commit("user/OPEN_SNACKBAR", errorPayload);
      }
    },
  },
};
</script>
